// Accordions
document.addEventListener("DOMContentLoaded", function () {
    const accordions = document.querySelectorAll('.accordion');

    accordions.forEach(accordion => {
        accordion.addEventListener('click', function() {
            this.classList.toggle('active');

            const panel = this.nextElementSibling;
            panel.classList.toggle('show');
        });
    });
});


$(document).ready(function() {
    $('.teaser').click(function() {
        var $content = $(this).next('.content');
        var $overlay = $(this).find('.overlay');

        if ($content.is(':visible')) {
            $overlay.fadeIn();
            $content.slideUp();
            
        } else {
            $('.content').slideUp();
            $('.overlay').fadeIn();
            $content.slideDown();
            $overlay.fadeOut();
        }
    });
});


$(document).ready(function() {
    $(".copy-btn").click(function() {
        var codeId = $(this).data("code"); 
        var code = $(codeId).text(); 
        var popup = $(this).data("popup");

        var tempInput = $("<input>");
        $("body").append(tempInput);
        tempInput.val(code).select();
        document.execCommand("copy");
        tempInput.remove();
        
        // Popup anzeigen
        var popup = $("#" + popup);
        popup.addClass("show");
        setTimeout(function() {
            popup.removeClass("show");
        }, 1500);
    });
});

/******************************* Bounty Slider *****************************/
$(document).ready(function(){
    var trikotOwl = $(".owl-carousel.bounty").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        responsiveClass:true,
        dotsContainer: '.bountyslider-custom-dots-container',
        //navText: ['<div class="owl-prev">Prev</div>', '<div class="owl-next">Next</div>'],
        responsive:{
            0:{
                items:2,
            },
            600:{
                items:3,
            },
            800:{
                items:4,
            },
            1000:{
                items:5,
            },
            1240:{
                items:7,
            }
        }
    });

    $('.bountyslider-custom-nav-container .owl-next, .bountyslider-custom-nav-container-2 .owl-next').click(function() {
        trikotOwl.trigger('next.owl.carousel');
    });

    $('.bountyslider-custom-nav-container .owl-prev, .bountyslider-custom-nav-container-2 .owl-prev').click(function() {
        trikotOwl.trigger('prev.owl.carousel');
    });
});


/******************************* Bounty Slider End*****************************/

/******************************* Trikot Slider *****************************/
$(document).ready(function(){
    var trikotOwl = $(".owl-carousel.trikot").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        responsiveClass:true,
        dotsContainer: '.trikotslider-custom-dots-container',
        //navText: ['<div class="owl-prev">Prev</div>', '<div class="owl-next">Next</div>'],
        responsive:{
            0:{
                items:2,
            },
            600:{
                items:3,
            },
            800:{
                items:4,
            },
            1000:{
                items:5,
            },
            1240:{
                items:7,
            }
        }
    });

    $('.trikotslider-custom-nav-container .owl-next, .trikotslider-custom-nav-container-2 .owl-next').click(function() {
        trikotOwl.trigger('next.owl.carousel');
    });

    $('.trikotslider-custom-nav-container .owl-prev, .trikotslider-custom-nav-container-2 .owl-prev').click(function() {
        trikotOwl.trigger('prev.owl.carousel');
    });
});

/******************************* Trikot Slider End *****************************/